export const ROUTES = [
  {
    id: "company-submenu",
    title: "Company",
    icon: "project",
    nestedRoutes: [
      {
        key: "external_home",
        id: "company-home-tab",
        title: "Home",
        icon: "project",
        link: "https://sfhcr.knowledgeloop.com/dashboard/index.html",
      },
      {
        key: "info",
        id: "company-info-tab",
        title: "Information",
        icon: "project",
      },
      {
        key: "statistic",
        id: "company-statistic-tab",
        title: "Statistic",
        icon: "project",
      },
      {
        key: "roles",
        id: "company-role-tab",
        title: "Qualifications",
        icon: "right-square",
      },
      {
        key: "departments",
        id: "company-departments-tab",
        title: "Departments",
        icon: "right-square",
      },
      {
        key: "pay-codes",
        id: "company-pc-tab",
        title: "Pay Codes",
        icon: "right-square",
      },
    ],
  },
  {
    id: "notification-submenu",
    title: "Notification",
    icon: "inbox",
    nestedRoutes: [
      {
        key: "mailing",
        id: "notification-notif-tab",
        title: "Send Announcement",
        icon: "notification",
      },
      {
        key: "alert-store",
        id: "notification-alerts-tab",
        title: "Alerts",
        icon: "alert",
      },
    ],
  },
  {
    id: "associate-submenu",
    title: "Associate",
    icon: "user",
    nestedRoutes: [
      {
        key: "shifts",
        id: "associate-shifts-tab",
        title: "Shifts",
        icon: "profile",
      },
      {
        key: "project-shift-auths",
        id: "associate-auths-tab",
        title: "Authorizations",
        icon: "file-done",
      },
      {
        key: "associate-pay-codes",
        id: "associate-pc-tab",
        title: "Pay Codes",
        icon: "barcode",
      },
      {
        key: "associate-profiles",
        id: "associate-profiles-tab",
        title: "Profiles",
        icon: "idcard",
      },
      {
        key: "activity",
        id: "associate-activity-tab",
        title: "Activity",
        icon: "user",
      },
      {
        key: "time-off",
        id: "associate-time-off-tab",
        title: "Time Off Requests",
        icon: "schedule",
      },
    ],
  },
  {
    id: "documents-submenu",
    title: "Documents",
    icon: "dollar",
    nestedRoutes: [
      {
        key: "documents-store",
        id: "documents-store-tab",
        title: "Documents Store",
        icon: "book",
      },
      {
        key: "onboarding",
        id: "onboarding-tab",
        title: "Onboarding",
        icon: "import",
      },
      {
        key: "trainings",
        id: "trainings-tab",
        title: "Trainings",
        icon: "solution",
      },
      {
        key: "departure",
        id: "departure-tab",
        title: "Departure",
        icon: "export",
      },
    ],
  },
  {
    id: "payments-submenu",
    title: "Payments",
    icon: "dollar",
    nestedRoutes: [
      {
        key: "payments-request",
        id: "payment-request-tab",
        title: "Payments Requests",
        icon: "right-square",
      },
      {
        key: "loan-request",
        id: "loan-request-tab",
        title: "Loan Requests",
        icon: "right-square",
      },
      {
        key: "payments-history",
        id: "payment-history-tab",
        title: "Payments",
        icon: "right-square",
      },
      {
        key: "active-payroll",
        id: "active-payroll-tab",
        title: "Active Payroll",
        icon: "right-square",
      },
      {
        key: "past-payrolls",
        id: "past-payrolls-tab",
        title: "Past Payrolls",
        icon: "right-square",
      },
      {
        key: "legal-entity",
        id: "legal-entity-tab",
        title: "Legal Entities",
        icon: "right-square",
      },
      {
        key: "payments-settings",
        id: "payment-settings-tab",
        title: "Settings",
        icon: "right-square",
      },
    ],
  },
  {
    id: "reports-submenu",
    title: "Reports",
    icon: "file-text",
    nestedRoutes: [
      {
        key: "daily-summary",
        id: "reports-daily-summary-tab",
        title: "Daily Summary",
        icon: "right-square",
      },
      {
        key: "time-attendance-detail-report",
        id: "reports-time-attendance-tab",
        title: "Time / Attendance Detail",
        icon: "right-square",
      },
      {
        key: "shift-card-report",
        id: "reports-shift-card-tab",
        title: "Shift Card",
        icon: "right-square",
      },
      {
        key: "ppd-audit-report",
        id: "reports-ppd-audit-tab",
        title: "PPD Audit Export",
        icon: "right-square",
      },
      {
        key: "external-system-report",
        id: "reports-external-system-tab",
        title: "Payroll System",
        icon: "right-square",
      },
      {
        key: "cross-system-report",
        id: "reports-cross-system-tab",
        title: "Cross-System Compliance",
        icon: "right-square",
      },
      {
        key: "total-hours-detail-report",
        id: "reports-total-hours-tab",
        title: "Total Hours Detail",
        icon: "right-square",
      },
      {
        key: "signed-shifts-report",
        id: "reports-signed-shifts-tab",
        title: "Signed Shifts",
        icon: "right-square",
      },
      {
        key: "not-in-work-area",
        id: "reports-not-in-work-area-tab",
        title: "Not in Work Area",
        icon: "right-square",
      },
      {
        key: "associate-report",
        id: "reports-associate-tab",
        title: "Associate",
        icon: "right-square",
      },
      {
        key: "overtime-report",
        id: "reports-overtime-tab",
        title: "Overtime",
        icon: "right-square",
      },
      {
        key: "mispunch-report",
        id: "reports-mispunch-tab",
        title: "Mispunch",
        icon: "right-square",
      },
      {
        key: "worked-shifts-report",
        id: "reports-worked-shifts-tab",
        title: "Worked Shifts",
        icon: "right-square",
      },
      {
        key: "pbj-report",
        id: "reports-pbj-tab",
        title: "PBJ",
        icon: "right-square",
        condition: "currentProjectIsSfhcr",
      },
      {
        key: "visitors",
        id: "visitors",
        title: "Visitors",
        icon: "right-square",
      },
    ],
  },
];
