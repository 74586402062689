<template>
  <div>
    <v-tour
      name="layoutTour"
      :steps="tourSteps"
      :callbacks="callbacks"
      :options="vueTourOptions"
    />

    <a-modal
      v-model="openedUserGuidesModal"
      title="User Guides"
      :footer="null"
      @close="userGuideModalClose"
    >
      <div class="content-view">
        <div class="content-view-block w-full">
          <p>
            <b>App Sections Tour</b>
            <a-button size="small" icon="play-circle" @click="startLayoutTour">
              Start
            </a-button>
          </p>

          <h4>Associate Pages</h4>
          <p>
            <b>Shifts</b>
            <a-button
              size="small"
              icon="play-circle"
              @click="startAssociateShiftsTour"
            >
              Start
            </a-button>
          </p>
          <p>
            <b>Profiles</b>
            <a-button
              size="small"
              icon="play-circle"
              @click="startAssociateProfilesTour"
            >
              Start
            </a-button>
          </p>
          <p>
            <b>Authorizations</b>
            <a-button size="small" icon="play-circle" @click="startAuthsTour">
              Start
            </a-button>
          </p>

          <h4>Payments Pages</h4>
          <p>
            <b>Payments</b>
            <a-button
              size="small"
              icon="play-circle"
              @click="startPaymentsTour"
            >
              Start
            </a-button>
          </p>
        </div>
      </div>
    </a-modal>
    <a-modal v-model="openedSettingsModal" title="Settings" width="650px">
      <settings />
      <template slot="footer">
        <a-button type="primary" @click="handleSettingsModalClose"
          >Close</a-button
        >
      </template>
    </a-modal>
    <div v-if="!isProd" class="dev-server">Dev</div>
    <div :class="{ dev: !isProd }">
      <a-layout id="layout" v-if="token && !$router.currentRoute.meta.public">
        <a-layout-sider
          v-if="token"
          class="sider no_print"
          theme="light"
          :width="250"
        >
          <div class="logo" @click="$router.push('/')">
            <img alt="ePeople Time" src="../assets/logo.png" height="60px" />
            <h3 class="company_name" :class="{ none: collapsed }" collapsible>
              ePeople Time
            </h3>
          </div>
          <div
            v-if="
              token &&
              user != null &&
              user.username != null &&
              user.username.length > 0
            "
            class="user-profile-wrapper"
          >
            <user-avatar :image-path="userAvatarLink" />
            <div class="user-info">
              <div class="user-details">
                <div class="flex flex-column">
                  <b>{{ userFullName }}</b>
                  <span>{{ projectName }}</span>
                  <span
                    >Active Payment Type: <br />
                    <b> {{ currentPaymentTypeName }}</b>
                  </span>
                </div>
                <a-dropdown class="user-dropdown">
                  <a-icon class="dropdown-icon-trigger" type="down" />
                  <a-menu slot="overlay" class="user-dropdown-menu">
                    <a-sub-menu title="Change Active Payment Type">
                      <a-menu-item @click="onPaymentTypeChange(null)">
                        All
                      </a-menu-item>

                      <a-menu-item
                        v-for="pt in paymentTypes"
                        v-if="pt.id != currentPaymentTypeId"
                        :key="pt.id"
                        @click="onPaymentTypeChange(pt.id)"
                      >
                        {{ pt.name }}
                      </a-menu-item>
                    </a-sub-menu>
                    <a-sub-menu
                      v-if="projects && projects.length > 1"
                      title="Change Associate or Company"
                    >
                      <a-menu-item
                        v-for="project in projects"
                        v-if="
                          !(
                            project.id === currentProject.id &&
                            project.associate_number === currentAssociateNumber
                          ) && project.is_active_associate
                        "
                        :key="project.id"
                        @click="
                          onProjectChange(project.id, project.associate_number)
                        "
                      >
                        {{ project.name }} [{{ project.associate_number }}]
                      </a-menu-item>
                    </a-sub-menu>
                    <a-menu-item @click="handleSettingsModalOpen">
                      Settings
                    </a-menu-item>
                    <a-menu-item @click="userGuideModalOpen">
                      User Guides
                    </a-menu-item>
                    <a-menu-item class="sign-out-button" @click="handleSignOut">
                      Sign Out
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </div>
            </div>
          </div>
          <a-menu
            ref="menu"
            theme="light"
            mode="inline"
            :selectedKeys="selectedKeys"
            v-bind="tourIsOn && { openKeys }"
            @click="handleClick"
          >
            <a-sub-menu v-for="menu in ROUTES" :key="menu.id">
              <span slot="title">
                <a-icon :type="menu.icon" />
                <span>{{ menu.title }}</span>
              </span>
              <a-menu-item
                v-for="item in menu.nestedRoutes"
                :key="item.key"
                :id="item.id"
              >
                <a target="_blank" :href="item.link" v-if="item.link">
                  <a-icon :type="item.icon" /> <span>{{ item.title }}</span></a
                >

                <router-link
                  v-else
                  :to="{ name: item.key }"
                  @click.native.prevent="handleClick({ key: item.key })"
                >
                  <a-icon :type="item.icon" />
                  <span>{{ item.title }}</span>
                </router-link>
              </a-menu-item>
            </a-sub-menu>
            <a-menu-item>
              <a target="_blank" href="https://reports.epeople.com/">
                <a-icon type="file-text" /> <span>Report Service</span></a
              >
            </a-menu-item>
          </a-menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-content style="margin: 0 16px; position: relative">
            <div
              class="flex align-items-center justify-content-center h-screen"
              v-if="loading"
            >
              <a-spin size="large" />
            </div>
            <div v-else class="content_wrapper">
              <slot />
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
      <router-view v-else />
    </div>
  </div>
</template>

<script>
import {
  Layout,
  Menu,
  Icon,
  Avatar,
  Button,
  Dropdown,
  notification,
  Modal,
  Spin,
} from "ant-design-vue";
import api from "@/api";
import Settings from "@/components/settings.vue";
import Util from "@/util";
import AllProjects from "@/views/all-projects.vue";
import UserAvatar from "@/components/user-avatar.vue";
import { vueTourOptions } from "@/const";
import { ROUTES } from "@/store/constants";

const SFHCR_PROJECT_ID = 1;
const submenuKeysList = [
  "company-submenu",
  "associate-submenu",
  "payments-submenu",
  "reports-submenu",
];

export default {
  components: {
    "a-layout": Layout,
    "a-layout-sider": Layout.Sider,
    "a-layout-header": Layout.Header,
    "a-layout-footer": Layout.Footer,
    "a-layout-content": Layout.Content,
    "a-menu": Menu,
    "a-menu-item": Menu.Item,
    "a-menu-divider": Menu.Divider,
    "a-sub-menu": Menu.SubMenu,
    "a-dropdown": Dropdown,
    "a-icon": Icon,
    "a-avatar": Avatar,
    "a-button": Button,
    "a-modal": Modal,
    "a-spin": Spin,
    settings: Settings,
    "all-projects": AllProjects,
    "user-avatar": UserAvatar,
  },
  data: function () {
    return {
      collapsed: false,
      openedSettingsModal: false,
      openedUserGuidesModal: false,
      selectedKeys: [],
      loading: false,

      tourSteps: [
        // COMPANY
        {
          target: "#company-info-tab",
          header: {
            title: "Information",
          },
          params: {
            placement: "right",
          },
          content:
            "In the Company Info section you can find comprehensive information about your company, including statistics and key metrics that give you an overview of the company's performance.",
        },
        {
          target: "#company-role-tab",
          header: {
            title: "Qualifications",
          },
          params: {
            placement: "right",
          },
          content:
            "In the Qualifications section, you can set up different qualifications in your company. This includes defining the privileges associated with each qualification to ensure proper role-based access and responsibilities.",
        },

        {
          target: "#company-pc-tab",
          header: {
            title: "Pay Codes",
          },
          params: {
            placement: "right",
          },
          content:
            "The Pay Codes section allows you to set up codes for different types of pay. For instance, you can use PayCodes to pay an associate for a vacation day or a sick day. This helps in accurately tracking and managing different pay scenarios.",
        },

        // NOTIFICATIONS
        {
          target: "#notification-notif-tab",
          header: {
            title: "Notifications",
          },
          params: {
            placement: "right",
          },
          content:
            "The Notifications section allows you to send important notifications to anyone in the company. Use this to keep your team informed about crucial updates and messages.",
        },
        {
          target: "#notification-alerts-tab",
          header: {
            title: "Alerts",
          },
          params: {
            placement: "right",
          },
          content:
            "The Alerts section contains information about actions performed by users that require the attention of an administrator, such as document signs and other critical activities.",
        },

        // ASSOCIATE
        {
          target: "#associate-shifts-tab",
          header: {
            title: "Shifts",
          },
          params: {
            placement: "right",
          },
          content:
            "In Shifts section you can review the shifts that your associates have worked. This allows you to track attendance and monitor work patterns effectively.",
        },
        {
          target: "#associate-auths-tab",
          header: {
            title: "Authorizations",
          },
          params: {
            placement: "right",
          },
          content:
            "In the Authorizations section, you can review actions taken by your associates that may contradict company policies. This helps you manage and address any rule violations.",
        },
        {
          target: "#associate-pc-tab",
          header: {
            title: "Pay Codes",
          },
          params: {
            placement: "right",
          },
          content:
            "In the Pay Codes section, you can create shifts using Pay Codes. This is useful for keeping track of days when your associates are not working, such as vacations or sick days.",
        },
        {
          target: "#associate-profiles-tab",
          header: {
            title: "Profiles",
          },
          params: {
            placement: "right",
          },
          content:
            "The Profiles section contains information about your associates. You can view and manage associate details to ensure your records are up-to-date.",
        },
        {
          target: "#associate-activity-tab",
          header: {
            title: "Activity",
          },
          params: {
            placement: "right",
          },
          content:
            "The Activity section is used to monitor the current activities of associates. You can see who is working, who has left, and other real-time status updates of your team.",
        },
        {
          target: "#associate-time-off-tab",
          header: {
            title: "Time Off Requests",
          },
          params: {
            placement: "right",
          },
          content:
            "The Time Off Requests section allows you to review requests for time off from your associates. This helps you manage and approve or deny requests efficiently.",
        },

        // DOCS
        {
          target: "#documents-store-tab",
          header: {
            title: "Documents Store",
          },
          params: {
            placement: "right",
          },
          content:
            "In the Documents Store section, you can manage all documents.",
        },

        // PAYMENTS
        {
          target: "#payment-request-tab",
          header: {
            title: "Payment Request",
          },
          params: {
            placement: "right",
          },
          content:
            "In the Payment Request section, you can manage all payment requests.",
        },
        {
          target: "#legal-entity-tab",
          header: {
            title: "Legal Entity",
          },
          params: {
            placement: "right",
          },
          content:
            "In the Agency section, you can specify the companies or agencies you work with that provide you with workers. Additionally, you can list companies for Sole Proprietors, ensuring all your external workforce sources are properly documented.",
        },
        {
          target: "#payment-settings-tab",
          header: {
            title: "Payment Settings",
          },
          params: {
            placement: "right",
          },
          content:
            'In the "Payment Settings" section, you can configure the settings for all payment requests.',
        },

        // REPORTS
        {
          target: "#reports-daily-summary-tab",
          header: {
            title: "Daily Summary",
          },
          params: {
            placement: "right",
          },
          content:
            "The Daily Summary report is crucial for understanding the day's operations. It provides key metrics, created and completed shifts, and offers a snapshot of the company's performance for the day. Reviewing this daily helps track the company's progress and identify trends.",
        },
        {
          target: "#reports-time-attendance-tab",
          header: {
            title: "Time / Attendance Detail",
          },
          params: {
            placement: "right",
          },
          content:
            "The Time / Attendance Detail report is essential for recording worked shifts. It allows you to export reports for a specific date range, detailing the work of individual associates. This report is often used for official records and internal audits.",
        },
        {
          target: "#reports-total-hours-tab",
          header: {
            title: "Total Hours Detail",
          },
          params: {
            placement: "right",
          },
          content:
            "The Total Hours Detail Record shows the total hours worked by associates over a selected period. It includes filtering options to customize the report as needed.",
        },
        {
          target: "#reports-signed-shifts-tab",
          header: {
            title: "Signed Shifts",
          },
          params: {
            placement: "right",
          },
          content:
            "The Signed Shifts report displays all the shifts that have been signed off by associates within a specific period. It helps ensure that all worked shifts are properly documented and reviewed.",
        },
        {
          target: "#reports-not-in-work-area-tab",
          header: {
            title: "Not in Work Area",
          },
          params: {
            placement: "right",
          },
          content:
            "The Not in Work Area report tracks actions taken by associates outside their assigned work areas. This is important for identifying unauthorized activities and ensuring compliance with company policies.",
        },
        {
          target: "#reports-associate-tab",
          header: {
            title: "Associate",
          },
          params: {
            placement: "right",
          },
          content:
            "The Associate report provides detailed information about current associates, including their profiles and statuses. It is useful for maintaining up-to-date records on all associates.",
        },
        {
          target: "#reports-overtime-tab",
          header: {
            title: "Overtime",
          },
          params: {
            placement: "right",
          },
          content:
            "The Overtime report tracks the amount of overtime worked by associates. It categorizes overtime into regular overtime (8 to 12 hours) and extended overtime (12 to 16 hours), helping to monitor labor costs and associate workload.",
        },
        {
          target: "#reports-mispunch-tab",
          header: {
            title: "Mispunch",
          },
          params: {
            placement: "right",
          },
          content:
            "The Mispunch Report identifies discrepancies in shift records, such as missed clock-ins or clock-outs. This helps ensure accurate time tracking and payroll processing.",
        },
        {
          target: "#reports-worked-shifts-tab",
          header: {
            title: "Worked Shifts",
          },
          params: {
            placement: "right",
          },
          content:
            "The Worked Shifts Report shows the number of whole shifts worked by day for a date range",
        },
        {
          target: "#reports-pbj-tab",
          header: {
            title: "PBJ Report",
          },
          params: {
            placement: "right",
          },
          content:
            "The PBJ Report is used for generating reports required for Payroll Based Journal submissions. It compiles all necessary information for regulatory compliance.",
        },
      ],
      callbacks: {
        onSkip: this.layoutTourFinishCallback,
        onFinish: this.layoutTourFinishCallback,
      },
      tourIsOn: false,

      openKeys: [],
      ROUTES,
    };
  },

  mixins: [api],

  computed: {
    vueTourOptions() {
      return vueTourOptions;
    },
    year() {
      return new Date().getFullYear();
    },
    currentRoute() {
      return this.$route.name || "";
    },
    user() {
      return this.$store.state.applicationState.user;
    },
    userFullName() {
      return Util.combineAssociateName(
        this.user.first_name,
        this.user.last_name
      );
    },
    token() {
      return this.$store.state.applicationState.token;
    },
    isProd() {
      return this.$store.state.applicationState.isProd;
    },
    projectName() {
      return (
        this.$store.state.applicationState.associate.associate_number +
          " | " +
          this.$store.state.applicationState.currentProject.name ?? "-"
      );
    },
    currentPaymentTypeId() {
      return this.$store.state.applicationState.currentPaymentType;
    },
    currentPaymentTypeName() {
      return this.$store.state.applicationState.currentPaymentTypeName ?? "-";
    },
    projects() {
      return this.$store.state.applicationState.allProjects;
    },
    currentProject() {
      return this.$store.state.applicationState.currentProject;
    },
    currentAssociate() {
      return this.$store.state.applicationState.associate;
    },
    currentAssociateNumber() {
      return this.currentAssociate.associate_number;
    },
    currentProjectIsSfhcr() {
      return this.currentProject
        ? this.currentProject.id == SFHCR_PROJECT_ID
        : false;
    },
    paymentTypes() {
      return this.$store.state.applicationState.paymentTypes;
    },

    userAvatarLink() {
      return this.$store.state.applicationState.user.profile_photo;
    },
  },

  watch: {
    currentRoute() {
      this.selectedKeys = [this.currentRoute];
    },

    token: {
      async handler(newVal, oldVal) {
        if (newVal && oldVal === null) return;
        if (newVal !== oldVal && newVal !== null) this.fetchAppData();
      },
      immediate: true,
    },
  },

  methods: {
    handleClick(e) {
      if (!e.key || e.key.startsWith("external_")) {
        return;
      }

      if (e.key == this.currentRoute) {
        return;
      }

      if (e.key == "home") {
        e.key = "";
      }

      this.$router.push("/" + e.key).catch(() => {});
    },
    async fetchAppData() {
      this.loading = true;

      try {
        await this.$store.dispatch("loadApplicationState");
      } catch {
      } finally {
        this.loading = false;
      }
    },

    async handleSignOut() {
      this.loading = true;
      try {
        await this.apiSignOut();
        this.$store.commit("setToken", null);
        await this.$router.push("/auth").catch(() => {});
      } catch {
      } finally {
        this.loading = false;
      }
    },

    async onProjectChange(newProjectId, newAssociateNumber) {
      this.loading = true;
      const oldProjectName = this.currentProject.name;
      const oldProjectId = this.currentProject.id;
      const oldAssociateNumber = this.currentAssociate.associate_number;

      if (
        oldAssociateNumber !== newAssociateNumber &&
        oldProjectId === newProjectId
      ) {
        try {
          await this.apiSwitchAssociate({
            project_id: newProjectId,
            current_associate_number: oldAssociateNumber,
            associate_number_to_switch: newAssociateNumber,
          });
        } catch (error) {
          console.error(
            "An error occurred when switching the associate:",
            error
          );
        }
      }

      try {
        const { body } = await this.apiSwitchCurrentProject(newProjectId);
        await this.fetchAppData();

        if (body.error_code && body.error_code !== "0") {
          notification["error"]({
            message: "Error",
            description:
              "An error occurred when switching the company. Please try again later",
          });
        } else {
          notification["success"]({
            message: "Success",
            description:
              "Company successfully switched from " +
              oldProjectName +
              " to " +
              this.currentProject.name,
          });
        }
      } catch (error) {
        notification["error"]({
          message: "Error",
          description:
            "An error occurred during the process. Please try again later",
        });
      } finally {
        this.loading = false;
      }
    },

    async onPaymentTypeChange(newPaymentTypeId) {
      this.loading = true;
      try {
        const { body } = await this.apiSetAdminPaymentType(newPaymentTypeId);
        await this.fetchAppData();

        if (body.error_code && body.error_code !== "0") {
          notification["error"]({
            message: "Error",
            description:
              "An error occurred when switching the payment type. Please try again later",
          });
        } else {
          notification["success"]({
            message: "Success",
            description:
              "Payment type successfully switched to " +
              this.currentPaymentTypeName,
          });
        }
      } catch (error) {
        notification["error"]({
          message: "Error",
          description:
            "An error occurred during the process. Please try again later",
        });
      } finally {
        this.loading = false;
      }
    },

    handleSettingsModalOpen() {
      this.openedSettingsModal = true;
    },
    handleSettingsModalClose() {
      this.openedSettingsModal = false;
    },

    userGuideModalOpen() {
      this.openedUserGuidesModal = true;
    },
    userGuideModalClose() {
      this.openedUserGuidesModal = false;
    },

    layoutTourStartPrepare() {
      this.tourIsOn = true;
      this.openKeys = [...submenuKeysList];
    },
    layoutTourFinishCallback() {
      localStorage.setItem("layoutTourPassed", true);

      this.tourIsOn = false;
      this.openKeys = [];
    },

    startLayoutTour() {
      this.userGuideModalClose();

      this.layoutTourStartPrepare();
      this.$tours["layoutTour"].start();
    },
    startAssociateShiftsTour() {
      this.userGuideModalClose();

      if (this.currentRoute === "shifts") {
        this.$nextTick(() => {
          this.$tours["shiftsTour"].start();
        });
      } else {
        this.$router.push("shifts").then(() => {
          this.$nextTick(() => {
            this.$tours["shiftsTour"].start();
          });
        });
      }
    },
    startAssociateProfilesTour() {
      this.userGuideModalClose();

      if (this.currentRoute === "associate-profiles") {
        this.$nextTick(() => {
          this.$tours["profilesTour"].start();
        });
      } else {
        this.$router.push("associate-profiles").then(() => {
          this.$nextTick(() => {
            this.$tours["profilesTour"].start();
          });
        });
      }
    },
    startPaymentsTour() {
      this.userGuideModalClose();

      if (this.currentRoute === "payments-request") {
        this.$nextTick(() => {
          this.$tours["paymentsTour"].start();
        });
      } else {
        this.$router.push("payments-request").then(() => {
          this.$nextTick(() => {
            this.$tours["paymentsTour"].start();
          });
        });
      }
    },

    startAuthsTour() {
      this.userGuideModalClose();

      if (this.currentRoute === "project-shift-auths") {
        this.$nextTick(() => {
          this.$tours["authsTour"].start();
        });
      } else {
        this.$router.push("project-shift-auths").then(() => {
          this.$nextTick(() => {
            this.$tours["authsTour"].start();
          });
        });
      }
    },
  },

  mounted() {
    const layoutTourPassed = localStorage.getItem("layoutTourPassed");

    if (this.$store.state.applicationState.user && !layoutTourPassed) {
      this.startLayoutTour();
    }

    this.selectedKeys = [this.currentRoute];
  },
};
</script>

<style scoped>
#layout {
  height: 100vh;
}

.dev #layout {
  height: calc(100vh - 32px);
}

#layout .sider {
  text-align: left !important;
  overflow-y: auto;
}

#layout .sider .logo {
  text-align: center !important;
  margin: 15px 0;
}

#layout .content_wrapper {
  padding: 12px;
  background: #fff;
  height: calc(100vh - 32px);
  overflow: auto;
}

.footer {
  text-align: center;
  padding: 20px 50px;
  min-height: 50px;
}

.company_name {
  margin: 0 !important;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.company_name.none {
  display: none;
  opacity: 0;
  max-width: 0;
}

.user-profile-wrapper {
  display: flex;
  padding: 5px;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  gap: 10px;
}

.user-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.user-details {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}

.sign-out-button {
  color: #ff0000;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.sign-out-button:hover {
  color: #ff4d4d;
}

.dropdown-icon-trigger:hover {
  cursor: pointer;
}

.unclickable-menu-item {
  cursor: unset !important;
  color: unset !important;
}

.user-dropdown-menu {
  width: 250px;
}
</style>
