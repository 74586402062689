<template>
  <div>
    <h2 class="view-header">
      <span class="flex gap-2 align-items-center justify-content-center">
        Associate Shifts
        <a-tooltip placement="right">
          <template slot="title"> Start Tour </template>
          <a-icon
            @click.stop="startTour()"
            class="flex align-items-center cursor-pointer"
            type="question-circle"
            theme="twoTone"
          />
        </a-tooltip>
      </span>
    </h2>
    <v-tour name="shiftsTour" :steps="tourSteps" :options="vueTourOptions" />
    <div>
      <associate-shifts-filters
        :current-filters="filterNew"
        :visible.sync="visibleFiltersModal"
      />

      <payment-mark-shift :visible.sync="visibleMarkPaidSeveralModal" />

      <shift-pay-code
        :initial-values="initialValues"
        :visible.sync="visiblePayCodeModal"
        @add="fetchShifts"
      />

      <shift-view
        :visible.sync="visibleShiftModal"
        :info="editingShift"
        @edit="fetchShifts"
      />

      <shift-move
        :initial-values="initialValues"
        :visible.sync="visibleMoveShiftsModal"
        @move="fetchShifts"
      />

      <associate-profile-view
        :visible.sync="visibleProfileModal"
        :associate_number.sync="editingAssociateNumber"
        @edit="fetchShifts"
      />

      <shift-sign
        :shift_id="signShiftId"
        :visible.sync="visibleSignModal"
        @edit="afterSignShift"
      />

      <add-multiple-shifts
        :visible="visibleAddMultipleShiftsModal"
        :initial-values="initialValues"
        @close="closeMultipleShiftsModal"
      />

      <send-notification
        :associate="associateProfileToSendSms"
        @close="closeSmsModal"
      />

      <shift-edit
        :visible.sync="visibleAddShiftModal"
        :initial-values="initialValues"
        @edit="fetchShifts"
      />

      <div class="table_controls_and_data">
        <div class="flex gap-2">
          <a-button icon="diff" type="primary" @click="openPayCodeModal"
            >Add Pay Code</a-button
          >
          <a-button type="primary" icon="diff" @click="openAddShiftModal">
            Add Shift
          </a-button>
          <a-button type="primary" icon="copy" @click="openMultipleShiftsModal">
            Add Several Shift
          </a-button>
          <a-button
            v-if="showMarkAsPaidButton"
            type="primary"
            icon="dollar"
            @click="openMarkPaidSeveralModal"
          >
            Create Manual Payment
          </a-button>
          <a-button type="primary" icon="export" @click="openMoveShiftsModal">
            Move Shifts
          </a-button>
        </div>

        <div class="flex gap-2">
          <a-badge :count="countFilters">
            <a-button icon="filter" @click="openFiltersModal">
              Filters
            </a-button>
          </a-badge>

          <a-button
            icon="undo"
            :disabled="countFilters === 0"
            @click="resetAssociateShiftsFilters"
          >
            Reset Filters
          </a-button>
        </div>
      </div>
      <a-table
        size="small"
        :columns="columns"
        :rowKey="(record) => record.id"
        :data-source="shifts"
        :loading="loading"
        :style="{ whiteSpace: 'pre' }"
        :locale="{ emptyText: getNoShiftsMessage }"
        :pagination="pagination"
        :expanded-row-render="expandedRowRender"
        :dropdown-match-select-width="false"
        :expand-row-by-click="true"
        :scroll="{ x: 1800, y: tableScrollHeight }"
        @change="handleTableChange"
        @expand="handleExpand"
      >
        <span slot="associate" slot-scope="text, record">
          <grouped-associate-info
            :first-name="record.associate.first_name"
            :last-name="record.associate.last_name"
            :photo-url="record.associate.photo_url"
            :unverified="!record.associate.is_verified"
            :associate-number="record.associate.associate_number"
          />
        </span>
        <span class="tags-cell" slot="status" slot-scope="text, record">
          <a-tooltip
            v-if="!record.shift.pay_code || record.shift.status === 'deleted'"
          >
            <template slot="title">
              {{
                record.shift.status !== "completed"
                  ? getShiftStatus(record.shift.status).text
                  : getShiftPaymentRequestStatus(record.shift.payment_status)
                      .text
              }}
            </template>
            <div class="flex flex-column gap-2">
              <a-tag
                class="auth-tag"
                :color="
                  record.shift.status !== 'completed'
                    ? getShiftStatus(record.shift.status).color
                    : getShiftPaymentRequestStatus(record.shift.payment_status)
                        .color
                "
                @click.stop="handleTagClick(record.shift)"
              >
                {{
                  record.shift.status !== "completed"
                    ? getShiftStatus(record.shift.status).title
                    : getShiftPaymentRequestStatus(record.shift.payment_status)
                        .title
                }}
              </a-tag>
            </div>
          </a-tooltip>

          <a-tooltip v-if="record.shift.pay_code">
            <template slot="title">
              {{ record.shift.pay_code.description }}
            </template>
            <div class="flex flex-column gap-2">
              <a-tag class="auth-tag" color="#d47aff">
                {{ record.shift.pay_code.name }}
              </a-tag>
            </div>
          </a-tooltip>

          <a-tooltip v-if="record.shift.retro_shift">
            <template slot="title">
              This is a shift that has been worked or is due to be worked more
              than one month ago
            </template>
            <a-tag class="auth-tag" :color="statusTagColors.GRAY">
              Retro Shift
            </a-tag>
          </a-tooltip>
        </span>
        <span slot="authorizations" slot-scope="text, record">
          <span
            v-if="
              record.shift.authorizations_with_status &&
              record.shift.authorizations_with_status.length > 0
            "
            class="flex flex-column gap-2"
          >
            <a-tag
              v-for="(auth, idx) in record.shift.authorizations_with_status"
              :key="idx"
              class="auth-tag"
              :color="getAuthStatusTagColor(auth.status)"
            >
              {{ convertType(auth.key, auth.status) }}
            </a-tag>
          </span>
          <span v-else>-</span>
        </span>
        <span slot="actions" slot-scope="text, record">
          <a-button-group size="small">
            <a-button
              id="open-shift-btn"
              icon="eye"
              @click.stop="
                openShiftModal(
                  record.shift.id,
                  record.associate.associate_number
                )
              "
            />

            <a-tooltip v-if="hasPermission('manage_associates')">
              <template slot="title"> Open Profile </template>
              <a-button
                icon="user"
                @click.stop="
                  startEditAssociateProfile(record.associate.associate_number)
                "
              />
            </a-tooltip>

            <a-tooltip v-if="hasPermission('send_push_notification')">
              <template slot="title"> Send Message </template>
              <a-button
                id="send-msg-btn"
                icon="message"
                :disabled="!record.associate.phone"
                @click.stop="openSmsSendModal(record.associate)"
              />
            </a-tooltip>
          </a-button-group>
        </span>
        <span slot="origin" slot-scope="text, record">
          <a-tag :color="getOrigin(record.shift.origin).color">
            {{ getOrigin(record.shift.origin).title }}
          </a-tag>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Table,
  Tag,
  Modal,
  Tooltip,
  notification,
  Icon,
  Badge,
} from "ant-design-vue";
import SendNotification from "@/components/send-notification.vue";
import AssociateShiftsFilters from "@/modules/shifts/components/shift-filters.vue";
import AddMultipleShifts from "@/modules/shifts/components/shift-multiply-modal.vue";
import { statusTagColors, vueTourOptions } from "@/const";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import ShiftPayCodeModal from "../components/shift-pay-code-create-modal.vue";
import ShiftViewModal from "../components/shift-view-modal.vue";
import ShiftSignModal from "../components/shift-sign-modal.vue";
import shiftFormVue from "../components/shift-form.vue";
import { createNamespacedHelpers } from "vuex";
import tour from "../mixins/ShiftsTourMixin";
import table from "../mixins/ShiftsTableMixin";
import ShiftMoveModal from "../components/shift-move-modal.vue";
import ProfileAssociateView from "@/modules/profile/components/profile-view.vue";
import paymentMarkShiftModalVue from "../../payment/components/payment-mark-shift-modal.vue";
import shiftAuths from "@/helpers/shift-auths";
import permissionMixin from "@/mixins/permissionMixin";

const { mapActions: shiftActions, mapGetters: shiftGetters } =
  createNamespacedHelpers("shifts");

const { mapActions: profileActions } = createNamespacedHelpers("profile");

const AuthStatus = Object.freeze({
  INITIAL: "initial",
  ACCEPTED: "accepted",
  DECLINED: "declined",
});

const excludedStatuses = [
  "deleted",
  "missed",
  "not_signed",
  "cancelled",
  "completed",
  "started",
  "scheduled",
];

const excludedPaymentStatuses = [
  "unpaid",
  "auth_issues",
  "not_paid",
  "draft",
  "paid",
  "in_progress",
  "partially_paid",
  "cancelled",
];

export default {
  components: {
    SendNotification,
    "a-table": Table,
    "a-tag": Tag,
    "a-button": Button,
    "a-button-group": Button.Group,
    "a-tooltip": Tooltip,
    "a-modal": Modal,
    "a-icon": Icon,
    "a-badge": Badge,
    "associate-shifts-filters": AssociateShiftsFilters,
    "add-multiple-shifts": AddMultipleShifts,
    "grouped-associate-info": GroupedAssociateInfo,
    "associate-profile-view": ProfileAssociateView,
    "shift-pay-code": ShiftPayCodeModal,
    "shift-view": ShiftViewModal,
    "shift-sign": ShiftSignModal,
    "shift-edit": shiftFormVue,
    "payment-mark-shift": paymentMarkShiftModalVue,
    "shift-move": ShiftMoveModal,
  },

  mixins: [tour, table, permissionMixin],
  props: {
    filterNew: {
      type: Object,
    },
    shift_ids: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      shifts: [],

      visiblePayCodeModal: false,
      visibleShiftModal: false,
      visibleAddShiftModal: false,
      visibleAddMultipleShiftsModal: false,
      visibleSignModal: false,
      visibleFiltersModal: false,
      visibleProfileModal: false,
      visibleMarkPaidSeveralModal: false,
      visibleMoveShiftsModal: false,

      editingShift: undefined,
      signShiftId: undefined,
      editingAssociateNumber: undefined,
      associateProfileToSendSms: undefined,

      initialValues: {
        associateNumber: undefined,
        start: null,
        end: null,
      },

      loading: false,
    };
  },

  computed: {
    ...shiftGetters([
      "getShiftStatus",
      "getOrigin",
      "getShiftPaymentRequestStatus",
    ]),

    showMarkAsPaidButton() {
      const permissions =
        this.$store.state.applicationState.associate.permissions ?? [];

      return permissions.some(
        (item) =>
          item.name === "validate_payments" || item.name === "process_payments"
      );
    },

    getNoShiftsMessage() {
      const appliedFilters = Object.entries(this.filterNew)
        .filter(([key, value]) => value && value.length > 0)
        .map(([key]) => key)
        .join(", ");

      return `No ${
        appliedFilters.length > 0 ? appliedFilters : "shifts"
      } here yet. The selected filters might have caused this. Try adjusting your search!`;
    },

    statusTagColors() {
      return statusTagColors;
    },

    countFilters() {
      let count = 0;

      const compareArray = (value, excludedArray) => {
        return (
          Array.isArray(value) &&
          JSON.stringify(value) === JSON.stringify(excludedArray)
        );
      };

      for (const [key, value] of Object.entries(this.filterNew)) {
        if (key === "statuses" && compareArray(value, excludedStatuses)) {
          continue;
        }

        if (
          key === "payment_statuses" &&
          compareArray(value, excludedPaymentStatuses)
        ) {
          continue;
        }

        if (Array.isArray(value)) {
          if (value.length > 0) count++;
        } else if (value !== null && value !== undefined && value !== "") {
          count++;
        }
      }

      return count;
    },

    vueTourOptions() {
      return vueTourOptions;
    },
  },
  watch: {
    filterNew: {
      handler(newVal, prevVal) {
        if (JSON.stringify(newVal) != JSON.stringify(prevVal)) {
          this.pagination.current = 1;
          this.fetchShifts();

          if (newVal.associate_ids && newVal.associate_ids.length > 0) {
            const [firstAssociateId] = newVal.associate_ids;

            this.fetchAssociate(firstAssociateId);
          } else {
            this.initialValues.associateNumber = undefined;
          }

          if (newVal.end_date) {
            this.initialValues.end = newVal.end_date;
          } else {
            this.initialValues.end = null;
          }

          if (newVal.start_date) {
            this.initialValues.start = newVal.start_date;
          } else {
            this.initialValues.start = null;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...shiftActions(["getShifts", "getShiftsById"]),
    ...profileActions(["getAssociateById"]),

    convertType: shiftAuths.convertType,

    getAuthStatusTagColor(status) {
      if (status == AuthStatus.ACCEPTED) {
        return "rgb(55, 138, 90)";
      } else if (status == AuthStatus.DECLINED) {
        return "red";
      }

      return "";
    },

    resetAssociateShiftsFilters() {
      this.$router.replace({ query: {} }).catch(() => {});
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    async fetchShifts() {
      const payload = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        filter: this.filterNew,
      };

      this.loading = true;

      let skipFinally = false;

      try {
        const data = this.shift_ids.length
          ? await this.getShiftsById({ ids: this.shift_ids })
          : await this.getShifts(payload);

        this.shifts = data.items;
        this.pagination.total = this.shift_ids.length
          ? data.items.length
          : data.total_count;
      } catch (error) {
        if (error === "canceled") {
          skipFinally = true;
          this.loading = true;
          return;
        }
        this.showNotification("error", "Error", error);
      } finally {
        if (!skipFinally) this.loading = false;
      }
    },

    async fetchAssociate(associate_id) {
      try {
        const { associate } = await this.getAssociateById({
          associate_id: associate_id,
        });

        this.initialValues.associateNumber = associate.associate_number;
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },

    openPayCodeModal() {
      this.visiblePayCodeModal = true;
    },

    openShiftModal(shift_id, associate_number) {
      this.visibleShiftModal = true;
      this.editingShift = {
        shift_id,
        associate_number,
      };
    },

    openSignModal() {
      this.visibleSignModal = true;
    },

    openMarkPaidSeveralModal() {
      this.visibleMarkPaidSeveralModal = true;
    },

    openMoveShiftsModal() {
      this.visibleMoveShiftsModal = true;
    },

    cancelMarkPaidSeveral(dataChanged) {
      this.visibleMarkPaidSeveralModal = false;

      if (dataChanged) {
        this.fetchShifts();
      }
    },

    afterSignShift() {
      this.signShiftId = undefined;
      this.fetchShifts();
    },

    handleTableChange(pagination) {
      const isPaginationChanged =
        pagination.current !== this.pagination.current ||
        pagination.pageSize !== this.pagination.pageSize;

      if (!isPaginationChanged) {
        return;
      }

      this.pagination = { ...pagination };

      if (!this.shift_ids.length) this.fetchShifts();
    },

    openSmsSendModal(record) {
      this.associateProfileToSendSms = {
        associate_number: record.associate_number,
        first_name: record.first_name,
        last_name: record.last_name,
      };
    },

    closeSmsModal() {
      this.associateProfileToSendSms = undefined;
    },

    startEditAssociateProfile(associateNumber) {
      this.editingAssociateNumber = associateNumber;
      this.visibleProfileModal = true;
    },

    openFiltersModal() {
      this.visibleFiltersModal = true;
    },

    openMultipleShiftsModal() {
      this.visibleAddMultipleShiftsModal = true;
    },

    closeMultipleShiftsModal(duplicatedShifts) {
      this.visibleAddMultipleShiftsModal = false;

      if (duplicatedShifts) {
        this.fetchShifts();
      }
    },

    openAddShiftModal() {
      this.visibleAddShiftModal = true;
    },

    handleTagClick(record) {
      if (record.status == "not_signed") {
        this.openSignModal();
        this.signShiftId = record.id;
      }
    },

    startTour() {
      this.$tours["shiftsTour"].start();
    },
  },
  mounted() {
    this.calculateTableHeight();
    window.addEventListener("resize", this.calculateTableHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.calculateTableHeight);
  },
};
</script>

<style scoped>
.role-select {
  min-width: 150px;
}

.auth-tag {
  text-align: center;
}

.auth-tag:hover,
.suspected-tag:hover {
  cursor: pointer;
}

.already-existing-shift-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tags-cell {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.table-associate-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}

.table-associate-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
}

.orange-highlight {
  background-color: #ffb546;
}

.expanded-row {
  background-color: #f4f4f4;
}
</style>
