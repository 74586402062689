<template>
  <div class="grid">
    <send-modal
      :visible="visibleSendModal"
      :loading="loadingSend"
      @close="closeSendModal"
      @send="sendReport"
    />

    <div class="col">
      <h3>Personal</h3>
      <a-descriptions :column="1" size="small" class="col-fixed p-0">
        <a-descriptions-item label="Photo">
          <user-avatar
            :width="64"
            :height="64"
            :image-path="
              this.associate.photo_url ? this.associate.photo_url : undefined
            "
          />
        </a-descriptions-item>
        <a-descriptions-item label="First Name">
          {{ associate.first_name }}
        </a-descriptions-item>
        <a-descriptions-item label="Last Name">
          {{ associate.last_name }}
        </a-descriptions-item>
        <a-descriptions-item label="Email">
          {{ associate.email }}
        </a-descriptions-item>
        <a-descriptions-item label="Phone">
          {{ associate.phone }}
        </a-descriptions-item>
        <a-descriptions-item label="Associate Number">
          {{ associate.associate_number }}
        </a-descriptions-item>
        <a-descriptions-item label="SSN">
          {{ associate.social_security_number ?? " - " }}
        </a-descriptions-item>
        <a-descriptions-item label="Birth Date">
          {{
            associate.birth_date
              ? formatDateString(associate.birth_date)
              : " - "
          }}
        </a-descriptions-item>
      </a-descriptions>
    </div>

    <div class="col">
      <h3>Address</h3>
      <a-descriptions :column="1" size="small" class="col-fixed p-0">
        <a-descriptions-item label="Address">
          {{ `${associate.address?.address_line1 ?? " - "}` }}
          {{ associate.address?.address_line2 }}
        </a-descriptions-item>
        <a-descriptions-item label="City">
          {{ associate.address?.city ?? " - " }}
        </a-descriptions-item>
        <a-descriptions-item label="State">
          {{ findStateByCode(associate.address?.state) ?? " - " }}
        </a-descriptions-item>
        <a-descriptions-item label="Country">
          {{ findCountryByCode(associate.address?.country) ?? " - " }}
        </a-descriptions-item>
        <a-descriptions-item label="Zip">
          {{ associate.address?.zipcode ?? " - " }}
        </a-descriptions-item>
      </a-descriptions>
    </div>

    <div class="col">
      <h3>Actions</h3>
      <div class="flex flex-column gap-4">
        <a-card title="Export Profile">
          <a-dropdown-button type="primary">
            Export
            <a-menu slot="overlay">
              <a-menu-item key="mail" @click="openSendModal">
                <a-icon type="mail" /> Send To Email
              </a-menu-item>
              <a-menu-item key="download" @click="downloadReport">
                <a-icon type="download" /> Download
              </a-menu-item>
            </a-menu>
          </a-dropdown-button>
        </a-card>

        <a-card title="Password">
          <div class="flex gap-2">
            <profile-password />
          </div>
        </a-card>
        <a-card title="Create New Profile">
          <p>
            Create another associate profile for someone to work with a
            different qualification, payment type, or agency.
          </p>
          <div class="flex gap-2">
            <a-button
              icon="usergroup-add"
              type="primary"
              @click="$emit('clone')"
            >
              Clone
            </a-button>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import SendModal from "@/components/send-modal.vue";
import {
  Button,
  Card,
  Descriptions,
  Dropdown,
  Menu,
  Icon,
  notification,
} from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";
import ProfileAssociatePassword from "./profile-associate-password.vue";
import constData from "@/helpers/const-data";
import moment from "moment-timezone";
import UserAvatar from "@/components/user-avatar.vue";

const { mapActions: profileActions, mapState: profileState } =
  createNamespacedHelpers("profile");

export default {
  components: {
    "a-button": Button,
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
    "a-card": Card,
    "a-dropdown-button": Dropdown.Button,
    "a-menu": Menu,
    "a-menu-item": Menu.Item,
    "a-icon": Icon,
    "user-avatar": UserAvatar,
    "send-modal": SendModal,
    "profile-password": ProfileAssociatePassword,
  },
  emits: ["clone"],
  data() {
    return {
      visibleSendModal: false,

      loadingSend: false,

      countriesList: constData.countries,
      statesList: constData.usStates,
    };
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
    }),
  },
  methods: {
    ...profileActions(["sendExportedAssociate", "downloadExportedAssociate"]),

    formatDateString(dateString) {
      return moment(dateString).utc().format("MM/DD/YYYY");
    },

    findStateByCode(code) {
      return this.statesList.find(
        (item) => item.code.toLowerCase() === code?.toLowerCase()
      )?.name;
    },

    findCountryByCode(code) {
      return this.countriesList.find(
        (item) => item.code.toLowerCase() === code?.toLowerCase()
      )?.name;
    },

    closeSendModal() {
      this.visibleSendModal = false;
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    openSendModal() {
      this.visibleSendModal = true;
    },

    async sendReport(email) {
      this.closeSendModal();

      this.loadingSend = true;

      const payload = {
        associate_id: this.associate.id,
        email,
      };

      try {
        await this.sendExportedAssociate(payload);

        this.showNotification(
          "success",
          "Success",
          "Exported Associate Profile successfully sent to email"
        );
      } catch (error) {
        this.showNotification("error", "Error", error.message || error);
      } finally {
        this.loadingSend = false;
      }
    },

    async downloadReport() {
      try {
        const url = await this.downloadExportedAssociate({
          associate_id: this.associate.id,
        });

        window.open(url, "_blank");
      } catch (error) {
        this.showNotification("error", "Error", error.message || error);
      }
    },
  },
};
</script>

<style scoped>
.ant-card {
  height: fit-content;
  border-radius: 10px;
}

.ant-descriptions {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

::v-deep .ant-descriptions-item-label {
  font-weight: bold;
  border: none;
  background-color: white;
}

::v-deep .ant-descriptions-row {
  border: none;
}

::v-deep .ant-descriptions-item-content {
  overflow-x: auto;
}

::v-deep .ant-descriptions-view {
  padding: 16px;
}

::v-deep .ant-descriptions-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
