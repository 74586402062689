<template>
  <div>
    <a-modal
      title="Edit Associate"
      :visible="visible"
      :destroy-on-close="true"
      width="1250px"
      @cancel="closeModal"
    >
      <div class="uncompleted-info grid">
        <div
          class="col-12 flex align-items-center"
          :style="{
            color: getMissedRule(item.key) ? 'green' : 'red',
            gap: '8px',
          }"
          v-for="item in uncompletedRules"
          :key="item.key"
        >
          {{ item.title }}
          <a-icon
            :type="getMissedRule(item.key) ? 'check-circle' : 'close-circle'"
          />
        </div>
      </div>

      <a-form-model
        layout="horizontal"
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{ span: 10 }"
        :wrapper-col="{ span: 14 }"
      >
        <div class="grid">
          <div class="col flex flex-column gap-2">
            <h3>Personal Info</h3>
            <a-form-model-item
              :colon="false"
              label-align="left"
              label="First Name"
              prop="associate.first_name"
            >
              <a-input
                v-model="form.associate.first_name"
                placeholder="First Name"
                autocomplete="off"
              />
            </a-form-model-item>

            <a-form-model-item
              :colon="false"
              label-align="left"
              label="Last Name"
              prop="associate.last_name"
            >
              <a-input
                v-model="form.associate.last_name"
                placeholder="Last Name"
                autocomplete="off"
              />
            </a-form-model-item>

            <a-form-model-item
              :colon="false"
              label-align="left"
              label="Email"
              prop="associate.email"
            >
              <a-input
                v-model="form.associate.email"
                placeholder="Email"
                autocomplete="off"
              />
            </a-form-model-item>
            <a-form-model-item
              :colon="false"
              label-align="left"
              label="Phone"
              prop="associate.phone"
            >
              <vue-tel-input
                v-model="form.associate.phone"
                mode="international"
                :enabled-country-code="true"
                :preferredCountries="['US']"
                :input-options="{
                  placeholder: 'Phone',
                  type: 'tel',
                  autocomplete: 'off',
                }"
                defaultCountry="US"
                :valid-characters-only="true"
              ></vue-tel-input>
            </a-form-model-item>

            <a-form-model-item
              :colon="false"
              label-align="left"
              label="Birth Date"
              prop="associate.birth_date"
            >
              <a-date-picker
                v-model="form.associate.birth_date"
                placeholder="Birth Date"
                class="w-full"
                :allowClear="true"
                :format="visualDateFormat"
              />
            </a-form-model-item>

            <a-form-model-item
              :colon="false"
              label-align="left"
              label="Address Line 1"
              prop="associate.address.address_line1"
            >
              <a-input
                v-model="form.associate.address.address_line1"
                placeholder="Enter Address"
              />
            </a-form-model-item>
            <a-form-model-item
              :colon="false"
              label-align="left"
              label="Address Line 2"
              prop="associate.address.address_line2"
            >
              <a-input
                v-model="form.associate.address.address_line2"
                placeholder="Enter Address"
              />
            </a-form-model-item>

            <a-form-model-item
              :colon="false"
              label-align="left"
              label="City"
              prop="associate.address.city"
            >
              <a-input
                v-model="form.associate.address.city"
                placeholder="Enter City"
              />
            </a-form-model-item>

            <a-form-model-item
              :colon="false"
              label-align="left"
              label="Country"
              prop="associate.address.country"
            >
              <a-select v-model="countryProxy" placeholder="Enter Country">
                <a-select-option
                  v-for="country in countriesList"
                  :key="country.code"
                  :value="country.code"
                >
                  {{ country.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item
              v-if="
                form.associate.address.country === 'US' ||
                form.associate.address.country === 'us'
              "
              :colon="false"
              label-align="left"
              label="State"
              prop="associate.address.state"
            >
              <a-select v-model="stateProxy" placeholder="Enter State">
                <a-select-option
                  v-for="state in statesList"
                  :key="state.code"
                  :value="state.code"
                >
                  {{ state.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item
              :colon="false"
              label-align="left"
              label="Zipcode"
              prop="associate.address.zipcode"
            >
              <a-input
                v-model="form.associate.address.zipcode"
                placeholder="Enter Zipcode"
              />
            </a-form-model-item>

            <a-collapse v-model="activeKey">
              <a-collapse-panel key="permissions" :header="permissionsTitle">
                <permissions-info
                  :permissions.sync="form.associate.permissions"
                  :is-visible-scope="associate.manager_scopes.length"
                />
              </a-collapse-panel>
              <a-collapse-panel
                key="authorizations"
                :header="authorizationsTitle"
              >
                <authorizations-info
                  :authorizations.sync="form.associate.available_auth_types"
                />
              </a-collapse-panel>
            </a-collapse>
          </div>
          <div class="col flex flex-column gap-2">
            <h3>Company Info</h3>

            <a-form-model-item
              :colon="false"
              label-align="left"
              label="Qualification"
              prop="associate.role.id"
            >
              <a-select
                v-model="form.associate.role.id"
                @select="openSuggestionModal"
                placeholder="Enter qualification"
              >
                <a-select-option
                  v-for="role in roles"
                  :key="role.id"
                  :value="role.id"
                >
                  {{ role.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item
              :colon="false"
              label-align="left"
              label="Schedule Type"
              prop="associate.schedule_type"
            >
              <a-select
                v-model="form.associate.schedule_type"
                placeholder="Enter schedule type"
              >
                <a-select-option
                  v-for="(value, key) in scheduleTypes"
                  :key="key"
                  :value="key"
                >
                  {{ value }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item
              :colon="false"
              label-align="left"
              label="Worker ID"
              prop="updated_associate_number"
            >
              <a-input
                v-model="form.updated_associate_number"
                placeholder="Worker ID"
                autocomplete="off"
              />
            </a-form-model-item>

            <a-form-model-item
              :colon="false"
              label-align="left"
              label="SSN"
              prop="associate.social_security_number"
            >
              <a-input
                v-model="form.associate.social_security_number"
                placeholder="SSN"
                autocomplete="off"
              />
            </a-form-model-item>

            <a-form-model-item
              :colon="false"
              label-align="left"
              label="Hire Date"
              prop="associate.hire_date"
            >
              <a-date-picker
                v-model="form.associate.hire_date"
                placeholder="Hire Date"
                class="w-full"
                :allowClear="true"
                :format="visualDateFormat"
              />
            </a-form-model-item>

            <a-form-model-item
              :colon="false"
              label-align="left"
              label="Termination Date"
              prop="associate.terminated_date"
            >
              <a-date-picker
                v-model="form.associate.terminated_date"
                placeholder="Termination Date"
                class="w-full"
                :allowClear="true"
                :format="visualDateFormat"
              />
            </a-form-model-item>

            <a-form-model-item
              :colon="false"
              label-align="left"
              label="Seniority Date"
              prop="associate.seniority_date"
            >
              <a-date-picker
                v-model="form.associate.seniority_date"
                :allowClear="true"
                placeholder="Seniority Date"
                class="w-full"
                :format="visualDateFormat"
              />
            </a-form-model-item>
          </div>
          <div class="col flex flex-column gap-2">
            <h3>Payment</h3>
            <a-form-model-item
              :colon="false"
              label-align="left"
              label="Payment Type"
              prop="associate.payment_info.type"
            >
              <a-select
                v-model="form.associate.payment_info.type"
                @select="openSuggestionModal"
                placeholder="Enter payment type"
              >
                <a-select-option
                  v-for="(value, key) in paymentTypes"
                  :key="key"
                  :value="key"
                >
                  {{ value }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <template v-if="form.associate.payment_info.type !== 'Volunteer'">
              <a-form-model-item
                :colon="false"
                label-align="left"
                label="Payment Method"
                prop="associate.payment_info.method"
              >
                <a-select
                  v-model="form.associate.payment_info.method"
                  placeholder="Enter payment method"
                >
                  <a-select-option
                    v-for="(value, key) in paymentMethods"
                    :key="key"
                    :value="key"
                  >
                    {{ value }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>

              <a-form-model-item
                :colon="false"
                label-align="left"
                label="Payment Status"
                prop="associate.payment_info.status"
              >
                <a-select
                  v-model="form.associate.payment_info.status"
                  placeholder="Enter payment status"
                >
                  <a-select-option
                    v-for="(value, key) in paymentStatuses"
                    :key="key"
                    :value="key"
                  >
                    {{ value }}
                  </a-select-option>
                </a-select>
              </a-form-model-item></template
            >

            <a-form-model-item
              v-if="form.associate.payment_info.type === 'Contractor'"
              :colon="false"
              label-align="left"
              label="Legal Entity"
              prop="associate.payment_info.legal_entity"
            >
              <a-select
                v-model="legalEntityProxy"
                @select="openSuggestionModal"
                placeholder="Legal Entity"
              >
                <a-select-option
                  v-for="agency in agencies"
                  :key="agency.id"
                  :value="agency.id"
                >
                  [{{ agency.id }}] - {{ agency.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item
              v-if="isVisibleRate"
              :colon="false"
              label="Hourly Rate, $"
              label-align="left"
              prop="associate.payment_info.hourly_rate"
            >
              <a-input-number
                v-model="rateProxy"
                :min="0"
                placeholder="Enter rate"
              />
            </a-form-model-item>

            <a-form-model-item
              v-if="
                isVisibleRate && form.associate.payment_info.method === 'salary'
              "
              :colon="false"
              label="Salary for Pay Period, $"
              label-align="left"
              prop="associate.payment_info.salary"
            >
              <a-input-number
                v-model="salaryProxy"
                :min="0"
                placeholder="Enter salary"
              />
            </a-form-model-item>

            <div class="flex flex-column gap-2">
              <h3>Params</h3>

              <div class="grid">
                <div class="col">
                  <p class="text-lg font-bold m-0">Allow Instant Payment</p>
                </div>
                <div class="col-fixed">
                  <a-switch
                    :checked="form.associate.allow_instant_payment"
                    @change="
                      (item) =>
                        handleSwitchChange(item, 'allow_instant_payment')
                    "
                  />
                </div>
              </div>

              <div class="grid">
                <div class="col">
                  <p class="text-lg font-bold m-0">
                    Shift Actions Autocomplete
                  </p>
                  <p>
                    The associate will automatically start and end first and
                    second meal break and finish work to prevent policy
                    violations in your company. Associate will self-sign the
                    shift and have the ability to change tracked work hours.
                  </p>
                </div>
                <div class="col-fixed">
                  <a-switch
                    :checked="form.associate.automatic_completing_actions"
                    @change="
                      (item) =>
                        handleSwitchChange(item, 'automatic_completing_actions')
                    "
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col">
                  <p class="text-lg font-bold m-0">Suspected Associate</p>
                  <p>
                    Activate if an associate misbehaves while on the job,
                    doesn't check in on the app, misses shift, violates company
                    policy or other rules
                  </p>
                </div>
                <div class="col-fixed">
                  <a-switch
                    :checked="form.associate.is_suspected"
                    @change="(item) => handleSwitchChange(item, 'is_suspected')"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col">
                  <p class="text-lg font-bold m-0">
                    Auto Approve Payment Request
                  </p>
                  <p>
                    Payment requests from this associate will be approved
                    automatically.
                  </p>
                </div>
                <div class="col-fixed">
                  <a-switch
                    :checked="form.associate.payment_auto_approve"
                    @change="
                      (item) => handleSwitchChange(item, 'payment_auto_approve')
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </a-form-model>
      <template slot="footer">
        <div class="flex justify-content-between">
          <a-button @click="closeModal">Close</a-button>
          <div class="flex justify-content-end">
            <a-button
              :loading="loading"
              @click="handleSubmit"
              icon="save"
              type="primary"
              ghost
              >Save</a-button
            >
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import RolePermissions from "@/modules/roles/components/role-permissions.vue";
import {
  Button,
  Collapse,
  DatePicker,
  Descriptions,
  FormModel,
  Icon,
  Input,
  InputNumber,
  Modal,
  notification,
  Select,
  Switch,
} from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";
import ProfileAuthorizations from "./profile-authorizations.vue";
import { PERMISSIONS } from "@/store/modules/roles/constants";
import { AUTH_STATUSES } from "@/store/modules/shifts/constants";
import ProfileManagerScope from "./profile-manager-scope.vue";
import constData from "@/helpers/const-data";
import { VueTelInput } from "vue-tel-input";
import moment from "moment-timezone";

const { mapActions: profileActions, mapState: profileState } =
  createNamespacedHelpers("profile");

export const agencyTypes = Object.freeze({
  independent_contractor: "Sole Proprietorship",
  agency: "Agency",
});

const scheduleTypes = Object.freeze({
  fixed: "Strict",
  self_schedule: "Flexible",
  free: "Free",
});

const paymentTypes = Object.freeze({
  Employee: "Employee",
  Contractor: "Contractor",
  Volunteer: "Volunteer",
});

const paymentStatuses = Object.freeze({
  on_call: "On Call / Per Diem",
  full_time: "Full Time",
  part_time: "Part Time",
  terminated: "Terminated",
});

const paymentMethods = Object.freeze({
  salary: "Salary",
  hourly: "Hourly",
});

const initialForm = {
  updated_associate_number: undefined,
  associate: {
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    phone: undefined,
    birth_date: undefined,
    address: {
      address_line1: undefined,
      address_line2: undefined,
      city: undefined,
      state: undefined,
      zipcode: undefined,
    },
    role: {
      id: undefined,
    },
    payment_info: {
      type: undefined,
      method: undefined,
      status: undefined,
      hourly_rate: undefined,
      legal_entity: undefined,
      salary: 0,
    },
    social_security_number: undefined,
    schedule_type: undefined,
    associate_number: undefined,
    allow_instant_payment: false,
    automatic_completing_actions: false,
    hire_date: undefined,
    terminated_date: undefined,
    seniority_date: undefined,
    is_suspected: false,
    lunch_waiver: false,
    payment_auto_approve: false,
    available_auth_types: [],
    permissions: [],
  },
};

const initialRules = {
  associate: {
    first_name: [
      {
        required: true,
        message: "First name can not be empty",
        trigger: "change",
      },
    ],
    last_name: [
      {
        required: true,
        message: "Last name can not be empty",
        trigger: "change",
      },
    ],
    email: [
      {
        required: true,
        message: "Email can not be empty",
        trigger: "change",
        type: "email",
      },
    ],
    phone: [
      {
        required: true,
        message: "Phone can not be empty",
        trigger: "change",
      },
      {
        transform: (value) => value.replace(/\s+/g, ""),
        pattern: /^\+\d{7,18}$/,
        message:
          "Invalid phone number. It should start with a + and contain 10 to 15 digits.",
      },
    ],
    address: {
      zipcode: [
        {
          min: 5,
          message: "The zipcode must be more than 4 characters.",
          trigger: "change",
        },
      ],
    },
    social_security_number: [
      {
        len: 9,
        message: "The SSN must be 9 characters.",
        trigger: "change",
      },
    ],
    role: {
      id: [
        {
          required: true,
          message: "Qualification can not be empty",
          trigger: "change",
        },
      ],
    },
    schedule_type: [
      {
        required: true,
        message: "Schedule type can not be empty",
        trigger: "change",
      },
    ],
    payment_info: {
      type: [
        {
          required: true,
          message: "Payment type can not be empty",
          trigger: "change",
        },
      ],
      method: [
        {
          required: true,
          message: "Payment method can not be empty",
          trigger: "change",
        },
      ],
      status: [
        {
          required: true,
          message: "Payment status can not be empty",
          trigger: "change",
        },
      ],
      legal_entity: [
        {
          required: true,
          message: "Legal Entity can not be empty",
          trigger: "change",
        },
      ],
      salary: [
        {
          required: true,
          message: "Salary can not be empty",
          trigger: "change",
          type: "number",
        },
        {
          min: 1,
          message: "Salary can not be empty",
          trigger: "change",
          type: "number",
        },
      ],
    },
  },
};

export default {
  components: {
    "a-modal": Modal,
    "a-button": Button,
    "a-input": Input,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-form-model-item": FormModel.Item,
    "a-form-model": FormModel,
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
    "a-input-number": InputNumber,
    "a-date-picker": DatePicker,
    "a-switch": Switch,
    "a-collapse": Collapse,
    "a-icon": Icon,
    "a-collapse-panel": Collapse.Panel,
    "permissions-info": RolePermissions,
    "authorizations-info": ProfileAuthorizations,
    "manager-scope-info": ProfileManagerScope,
    VueTelInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["clone"],
  data() {
    return {
      form: { ...initialForm },
      rules: {
        ...initialRules,
        updated_associate_number: [
          {
            required: true,
            message: "Associate number can not be empty",
            trigger: "change",
          },
          {
            len: 6,
            message: "The associate number must be 6 characters.",
            trigger: "change",
          },
          {
            validator: this.associateNumberValidator,
            trigger: "change",
          },
        ],
      },

      agencyTypes: agencyTypes,
      paymentMethods: paymentMethods,
      paymentStatuses: paymentStatuses,
      paymentTypes: paymentTypes,
      scheduleTypes: scheduleTypes,
      roles: this.$store.state.applicationState.projectRoles,
      agencies:
        this.$store.state.applicationState.currentProject.legal_entities,

      isInitializing: true,
      loading: false,

      activeKey: [],

      countriesList: constData.countries,
      statesList: constData.usStates,
    };
  },
  watch: {
    "form.associate.address.country": {
      handler(newVal) {
        if (newVal && newVal.toUpperCase() !== "US")
          this.form.associate.address.state = undefined;
      },
    },
    "form.associate.payment_info.type": {
      handler(newVal) {
        if (newVal !== "Volunteer") {
          this.updateRulesWithRate();
        }

        if (this.isInitializing) return;

        Object.assign(this.form.associate.payment_info, {
          legal_entity: undefined,
          hourly_rate: 0,
          ...this.getPaymentInfoByType(newVal),
        });
      },
      immediate: false,
    },
    "form.associate.payment_info.method": {
      handler(newVal) {
        if (!this.isInitializing) {
          if (newVal === "salary") {
            this.form.associate.payment_info.status = "full_time";
          } else {
            this.form.associate.payment_info.status = undefined;
          }
        }
      },
      immediate: false,
    },
    visible: {
      handler(newVal) {
        if (newVal) {
          this.form = {
            associate: JSON.parse(JSON.stringify(this.associate)),
            updated_associate_number: this.associate.associate_number,
          };

          const dateFields = [
            "hire_date",
            "birth_date",
            "resigned_date",
            "seniority_date",
            "terminated_date",
          ];

          dateFields.forEach((item) => {
            if (this.form.associate[item]) {
              this.form.associate[item] = moment(
                this.form.associate[item]
              ).utc();
            }
          });

          this.form.associate.address = !!this.associate.address
            ? JSON.parse(JSON.stringify(this.associate.address))
            : {
                ...initialForm.associate.address,
              };

          this.$nextTick(() => {
            this.isInitializing = false;
          });
        }

        if (!newVal) {
          this.isInitializing = true;
          this.activeKey = [];
          this.form = { ...initialForm };
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...profileState({
      associate: (state) => state.associate,
    }),

    uncompletedRules() {
      const rules = [
        { title: "Hire Date", key: "associate.hire_date" },
        { title: "Seniority Date", key: "associate.seniority_date" },
        { title: "Job Classification", key: "associate.role.id" },
      ];

      if (this.form.associate.payment_info.type !== "Volunteer") {
        rules.push({
          title: "Payment Type",
          key: "associate.payment_info.type",
        });
        rules.push({
          title: "Payment Status",
          key: "associate.payment_info.status",
        });
        rules.push({
          title: "Payment Method",
          key: "associate.payment_info.method",
        });
        rules.push({
          title: "Hourly Rate",
          key: "associate.payment_info.hourly_rate",
        });
      }

      if (this.form.associate.payment_info.method === "salary") {
        rules.push({ title: "Salary", key: "associate.payment_info.salary" });
      }

      if (this.form.associate.payment_info.type === "Contractor") {
        rules.push({
          title: "Legal Entity",
          key: "associate.payment_info.legal_entity",
        });
      }

      return rules;
    },

    rateProxy: {
      get() {
        return this.form.associate.payment_info.hourly_rate / 100;
      },
      set(value) {
        this.form.associate.payment_info.hourly_rate = Math.round(value * 100);
      },
    },

    salaryProxy: {
      get() {
        if (!this.form.associate.payment_info.salary) {
          this.form.associate.payment_info = {
            ...this.form.associate.payment_info,
            salary: 0,
          };
        }

        return this.form.associate.payment_info?.salary / 100;
      },
      set(value) {
        this.form.associate.payment_info.salary = Math.round(value * 100);
      },
    },

    stateProxy: {
      get() {
        return this.form.associate.address.state
          ? this.form.associate.address.state.toUpperCase()
          : undefined;
      },
      set(value) {
        this.form.associate.address.state = value;
      },
    },

    countryProxy: {
      get() {
        return this.form.associate.address.country
          ? this.form.associate.address.country.toUpperCase()
          : undefined;
      },
      set(value) {
        this.form.associate.address.country = value;
      },
    },

    legalEntityProxy: {
      get() {
        return this.form.associate.payment_info.legal_entity
          ? this.form.associate.payment_info.legal_entity.id
          : undefined;
      },
      set(value) {
        const foundedLegalEntity = this.agencies.find(
          (item) => item.id === value
        );

        this.form.associate.payment_info.legal_entity = JSON.parse(
          JSON.stringify(foundedLegalEntity)
        );
      },
    },

    permissionsTitle() {
      return `Permissions ${this.form.associate.permissions.length} / ${
        Object.keys(PERMISSIONS).length
      }`;
    },

    authorizationsTitle() {
      return `Available Authorizations ${
        this.form.associate.available_auth_types.length
      } / ${Object.keys(AUTH_STATUSES).length}`;
    },

    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },

    isVisibleRate() {
      switch (this.form.associate.payment_info.type) {
        case "Employee": {
          return !!this.form.associate.payment_info.method;
        }
        case "Volunteer": {
          return false;
        }
        case "Contractor": {
          return true;
        }
        default:
          return false;
      }
    },

    isUpdateAssociateNumber() {
      return (
        this.form.updated_associate_number.trim() ===
        this.form.associate.associate_number
      );
    },
  },
  methods: {
    ...profileActions([
      "checkAvailableAssociateNumber",
      "reloadAssociate",
      "updateAssociate",
    ]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    getMissedRule(rule) {
      const keys = rule.split(".");

      let value = this.form;

      for (const key of keys) {
        if (value && key in value) {
          value = value[key];
        } else {
          value = null;
          break;
        }
      }
      return (
        value !== null && value !== undefined && value !== "" && value !== 0
      );
    },

    getPaymentInfoByType(type) {
      const paymentConfig = {
        Employee: { method: "hourly", status: "full_time" },
        Volunteer: { method: "hourly", status: "on_call" },
        Contractor: { method: "hourly", status: "on_call" },
      };

      return paymentConfig[type] || {};
    },

    updateRulesWithRate() {
      const rateRules = [
        {
          required: true,
          message: "Rate can not be empty",
          trigger: "change",
          type: "number",
        },
        {
          min: 1,
          message: "Rate can not be empty",
          trigger: "change",
          type: "number",
        },
      ];

      this.rules = {
        ...this.rules,
        associate: {
          ...this.rules.associate,
          payment_info: {
            ...this.rules.associate.payment_info,
            hourly_rate: rateRules,
          },
        },
      };
    },

    handleSwitchChange(value, name) {
      this.form.associate[name] = value;
    },

    closeModal() {
      this.$emit("update:visible", false);
    },

    openSuggestionModal() {
      Modal.confirm({
        title: "You can create a new profile",
        content:
          "Instead of changing the current profile, you can create a copy of the associate's profile. This allows you to work with a new associate profile while keeping a previous one unchanged.",
        okText: "Create New Profile",
        okType: "primary",
        cancelText: "Update Current Profile",
        onOk: () => {
          this.closeModal();
          this.$emit("clone");
        },
        onCancel: () => {},
      });
    },

    async associateNumberValidator(rule, value, callback) {
      try {
        if (this.isUpdateAssociateNumber) {
          return;
        }

        const result = await this.checkAvailableAssociateNumber({
          associate_number: value,
        });

        if (result.owner) {
          callback(
            new Error(
              `This associate number is already occupied by another user in this company. You can use available associate number: ${result.available_number} `
            )
          );
        }
      } catch (error) {
        this.showNotification("error", "Error", error);
      }
    },

    handleSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;

        try {
          this.loading = true;
          this.form.associate.phone = this.form.associate.phone.replace(
            /\s+/g,
            ""
          );

          const payload = {
            associate: JSON.parse(JSON.stringify(this.form.associate)),
            updated_associate_number: this.isUpdateAssociateNumber
              ? undefined
              : this.form.updated_associate_number,
          };

          await this.updateAssociate(payload);

          this.showNotification(
            "success",
            "Info",
            `Associate has been edited successfully`
          );

          this.closeModal();

          if (!this.isUpdateAssociateNumber) {
            this.$emit("edit", this.form.updated_associate_number);
          }

          this.reloadAssociate(true);
        } catch (error) {
          this.showNotification("error", "Error", error);
        } finally {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style scoped>
::v-deep .ant-modal-body {
  height: 720px;
  overflow: auto;
}

.ant-descriptions {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

::v-deep .ant-descriptions-item-label {
  font-weight: bold;
  border: none;
  background-color: white;
}

::v-deep .ant-descriptions-row {
  border: none;
}

::v-deep .ant-descriptions-item-content {
  overflow-x: auto;
}

::v-deep .ant-descriptions-view {
  padding: 16px;
}

::v-deep .ant-descriptions-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ant-row {
  display: flex;
  margin: 0 !important;
}

.ant-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}

.ant-input-number {
  width: 100%;
}

.ant-calendar-picker {
  margin: 0;
  min-width: unset !important;
}

::v-deep .ant-collapse-content-box {
  height: 200px;
  overflow: auto;
}

.uncompleted-info {
  position: absolute;
  right: -220px;
  background-color: white;
  width: 200px;
  height: fit-content;
  top: 6px;
  border-radius: 4px;
  padding: 16px 24px;
}
</style>
