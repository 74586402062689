import store from "@/store";
import AlertStore from "@/modules/alerts/views/alert-store.vue";
import AssociateOverview from "@/views/associate-activity.vue";
import AssociatePayCodes from "@/modules/shifts/views/shifts-pay-codes.vue";
import AssociateProfiles from "@/modules/profile/views/associate-profiles.vue";
import EmplRep from "@/modules/report/views/associate-report.vue";
import Auth from "@/views/auth.vue";
import CreateUserWithAssociate from "@/views/create-user-with-associate.vue";
import GeneralDailyReport from "@/modules/report/views/daily-summary.vue";
import Dev from "@/views/dev.vue";
import MispunchReport from "@/modules/report/views/mispunch-report.vue";
import OvertimeReport from "@/modules/report/views/overtime-report.vue";
import PbjReport from "@/modules/report/views/pbj-report.vue";
import ProjectShiftAuths from "@/modules/shifts/views/shift-authorizations.vue";
import Project from "@/modules/project/views/project.vue";
import Mailing from "@/views/mailing.vue";
import SignedShiftsReport from "@/modules/report/views/signed-shifts-report.vue";
import TimeOffRequests from "@/views/time-off-requests.vue";
import TotalHoursDetailReport from "@/modules/report/views/total-hours-detail-report.vue";
import WorkedShiftsReport from "@/modules/report/views/worked-shifts-report.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import TimeCards from "../modules/shifts/views/shifts.vue";
import PayCodes from "../modules/paycode/views/pay-codes.vue";
import Shifts from "../views/shifts.vue";
import Visitors from "../views/visitors.vue";

Vue.use(VueRouter);

const normalizeToArray = (param) =>
  Array.isArray(param) ? param : param ? [param] : undefined;

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    name: "info",
    component: Project,
    props: true,
    meta: { title: "Company - ePeople Time" },
  },
  {
    path: "/no-permissions",
    name: "no-permissions",
    component: () => import("../views/no-permissions.vue"),
    meta: { title: "ePeople Time" },
  },
  {
    path: "/statistic",
    name: "statistic",
    component: () => import("../modules/project/views/statistic.vue"),
    props: true,
    meta: {
      title: "Statistic - ePeople Time",
      permissions: ["manage_project"],
    },
  },
  {
    path: "/auth",
    name: "auth",
    component: Auth,
    props: true,
    meta: { public: true, title: "ePeople Time" },
  },
  {
    path: "/attach-files",
    name: "attach-files",
    component: () => import("../views/attach-files.vue"),
    props: (route) => {
      return {
        associate_id: route.query.associate_id,
        shift_id: route.query.shift_id,
        created_at: route.query.created_at,
      };
    },
    meta: { public: true, title: "Attach Files - ePeople Time" },
  },
  {
    path: "/create-associate-document",
    name: "create-associate-document",
    component: () => import("../views/create-associate-documents.vue"),
    props: (route) => {
      return {
        associate_id: route.query.associate_id,
        template_id: route.query.template_id,
        created_at: route.query.created_at,
      };
    },
    meta: { public: true, title: "Create Associate Document - ePeople Time" },
  },
  {
    path: "/shifts",
    name: "shifts",
    component: TimeCards,
    props: (route) => {
      const defaultValues = {
        statuses: [
          "deleted",
          "missed",
          "not_signed",
          "cancelled",
          "completed",
          "started",
          "scheduled",
        ],
        payment_statuses: [
          "unpaid",
          "auth_issues",
          "not_paid",
          "draft",
          "paid",
          "in_progress",
          "partially_paid",
          "cancelled",
        ],
      };

      return {
        filterNew: {
          end_date: route.query.end_date,
          start_date: route.query.start_date,
          role: route.query.role,
          associate_ids: normalizeToArray(route.query.associate_ids),
          shift_ids: normalizeToArray(route.query.shift_ids),
          statuses: normalizeToArray(
            route.query.statuses ?? defaultValues.statuses
          ),
          auth_types: normalizeToArray(route.query.auth_types),
          payment_statuses: normalizeToArray(
            route.query.payment_statuses ?? defaultValues.payment_statuses
          ),
          payment_type: route.query.payment_type,
          shift_type: route.query.shift_type,
          manually_created: route.query.manually_created,
          with_pay_codes: route.query.with_pay_codes,
          pay_code: route.query.pay_code,
          attached_pay_code: route.query.attached_pay_code,
          with_files: route.query.with_files,
          adjusted: route.query.adjusted,
          origin: route.query.origin,
        },
        shift_ids: normalizeToArray(route.query.shift_ids),
      };
    },
    meta: {
      title: "Associate Shifts - ePeople Time",
      permissions: ["manage_shifts"],
    },
  },

  {
    path: "/associate-pay-codes",
    name: "associate-pay-codes",
    component: AssociatePayCodes,
    meta: {
      title: "Associate Pay Codes - ePeople Time",
      permissions: ["manage_shifts"],
    },
  },
  {
    path: "/pay-codes",
    name: "pay-codes",
    component: PayCodes,
    props: true,
    meta: { title: "Pay Codes - ePeople Time" },
  },
  {
    path: "/shifts-dictionary",
    name: "shifts-dictionary",
    component: Shifts,
    props: true,
    meta: { title: "Shifts Dictionary - ePeople Time" },
  },

  {
    path: "/payments-request",
    name: "payments-request",
    component: () => import("../modules/payment/views/payments.vue"),
    props: (route) => {
      return {
        filter: {
          start: route.query.start,
          end: route.query.end,
          associates: normalizeToArray(route.query.associates),
          statuses: normalizeToArray(route.query.statuses),
          system: route.query.system,
          history: route.query.history,
        },
        type: "payment",
      };
    },
    meta: {
      title: "Payments Requests - ePeople Time",
      permissions: ["validate_payments", "process_payments"],
    },
  },
  {
    path: "/loan-request",
    name: "loan-request",
    component: () => import("../modules/payment/views/payments.vue"),
    props: (route) => {
      return {
        filter: {
          start: route.query.start,
          end: route.query.end,
          associates: normalizeToArray(route.query.associates),
          statuses: normalizeToArray(route.query.statuses),
          system: route.query.system,
          history: route.query.history,
        },
        type: "loan",
      };
    },
    meta: {
      title: "Loan Requests - ePeople Time",
      permissions: ["validate_payments", "process_payments"],
    },
  },
  {
    path: "/payments-history",
    name: "payments-history",
    component: () => import("../modules/payment/views/payments.vue"),
    props: (route) => {
      return {
        filter: {
          start: route.query.start,
          end: route.query.end,
          associates: normalizeToArray(route.query.associates),
          statuses: normalizeToArray(route.query.statuses),
          system: route.query.system,
          history: route.query.history,
        },
        type: "history",
        request_ids: normalizeToArray(route.query.request_ids),
      };
    },
    meta: {
      title: "Payments - ePeople Time",
      permissions: ["validate_payments", "process_payments"],
    },
  },
  {
    path: "/payments-settings",
    name: "payments-settings",
    component: () => import("../views/payments-settings.vue"),
    meta: {
      title: "Payments Settings - ePeople Time",
      permissions: ["process_payments"],
    },
  },
  {
    path: "/associate-report",
    name: "associate-report",
    component: EmplRep,
    props: true,
    meta: { title: "Associate Report - ePeople Time" },
  },
  {
    path: "/overtime-report",
    name: "overtime-report",
    component: OvertimeReport,
    props: true,
    meta: { title: "Overtime Report - ePeople Time" },
  },
  {
    path: "/total-hours-detail-report",
    name: "total-hours-detail-report",
    component: TotalHoursDetailReport,
    props: true,
    meta: { title: "Total Hours Detail - ePeople Time" },
  },
  {
    path: "/mispunch-report",
    name: "mispunch-report",
    component: MispunchReport,
    props: true,
    meta: { title: "Mispunch Report - ePeople Time" },
  },
  {
    path: "/signed-shifts-report",
    name: "signed-shifts-report",
    component: SignedShiftsReport,
    props: true,
    meta: { title: "Signed Shifts Report - ePeople Time" },
  },
  {
    path: "/roles",
    name: "roles",
    component: () => import("../modules/roles/view/roles.vue"),
    props: true,
    meta: { title: "Qualifications Dictionary - ePeople Time" },
  },
  {
    path: "/departments",
    name: "departments",
    component: () => import("../modules/department/views/departments.vue"),
    props: true,
    meta: { title: "Departments - ePeople Time" },
  },
  {
    path: "/legal-entity",
    name: "legal-entity",
    component: () => import("../modules/legal-entity/views/legal-entity.vue"),
    props: (route) => {
      return {
        filters: {
          id: normalizeToArray(route.query.id),
          type: route.query.type,
        },
      };
    },
    meta: { title: "Legal Entity - ePeople Time" },
  },
  {
    path: "/not-in-work-area",
    name: "not-in-work-area",
    component: () => import("../modules/report/views/associate-not-in-wa.vue"),
    props: true,
    meta: { title: "Not in Work Area Report - ePeople Time" },
  },
  {
    path: "/active-associates",
    name: "active-associates",
    component: () => import("../views/active-associates.vue"),
    props: true,
    meta: {
      title: "Active Associates - ePeople Time",
      permissions: ["manage_associates"],
    },
  },
  {
    path: "/time-attendance-detail-report",
    name: "time-attendance-detail-report",
    component: () =>
      import("../modules/report/views/time-attendance-detail-report.vue"),
    props: true,
    meta: { title: "Time Attendance Detail Report - ePeople Time" },
  },
  {
    path: "/pbj-report",
    name: "pbj-report",
    component: PbjReport,
    props: true,
    meta: { title: "PBJ Report - ePeople Time" },
  },
  {
    path: "/worked-shifts-report",
    name: "worked-shifts-report",
    component: WorkedShiftsReport,
    props: true,
    meta: { title: "Worked Shifts Report - ePeople Time" },
  },
  {
    path: "/external-system-report",
    name: "external-system-report",
    component: () =>
      import("../modules/report/views/report-payment-system.vue"),
    props: true,
    meta: { title: "External System Report - ePeople Time" },
  },
  {
    path: "/cross-system-report",
    name: "cross-system-report",
    component: () => import("../modules/report/views/cross-system-report.vue"),
    props: true,
    meta: { title: "Cross-System Compliance Report - ePeople Time" },
  },
  {
    path: "/mailing",
    name: "mailing",
    component: Mailing,
    props: true,
    meta: {
      title: "Send Announcement - ePeople Time",
      permissions: ["send_push_notification"],
    },
  },
  {
    path: "/visitors",
    name: "visitors",
    component: Visitors,
    props: true,
    meta: { title: "Visitors - ePeople Time" },
  },
  {
    path: "/payroll",
    name: "payroll",
    component: () => import("../modules/payroll/views/payroll.vue"),
    props: (route) => ({
      tab: route.query.tab ?? "shifts",
      id: route.query.id,
    }),
    beforeEnter(to, from, next) {
      store.commit("payroll/resetState");
      next();
    },
    meta: {
      title: "Payroll - ePeople Time",
      permissions: ["validate_payments", "process_payments"],
    },
  },
  {
    path: "/active-payroll",
    name: "active-payroll",
    component: () => import("../modules/payroll/views/active-payroll.vue"),
    props: (route) => ({
      tab: route.query.tab ?? "associates",
    }),
    beforeEnter(to, from, next) {
      store.commit("payroll/resetState");
      next();
    },
    meta: {
      title: "Active Payroll - ePeople Time",
      permissions: ["validate_payments", "process_payments"],
    },
  },
  {
    path: "/past-payrolls",
    name: "past-payrolls",
    component: () => import("../modules/payroll/views/payroll-history.vue"),
    props: true,
    meta: {
      title: "Past Payrolls - ePeople Time",
      permissions: ["validate_payments", "process_payments"],
    },
  },
  {
    path: "/payroll-associate-shifts",
    name: "payroll-associate-shifts",
    component: () =>
      import("../modules/payroll/views/payroll-associate-shifts.vue"),
    props: (route) => ({
      associateId: route.query.associateId,
      payrollId: route.query.payrollId,
    }),
    beforeEnter: (to, from, next) => {
      const fromActivePayroll =
        from.name === "active-payroll" || from.name === "payroll";

      if (!to.query.associateId || !to.query.payrollId) {
        next({ name: "active-payroll" });
      } else if (!fromActivePayroll) {
        next();
      } else {
        next();
      }
    },
    meta: {
      title: "Payroll Associate Shifts - ePeople Time",
      permissions: ["validate_payments", "process_payments"],
    },
  },
  {
    path: "/payroll-associate-payments",
    name: "payroll-associate-payments",
    component: () =>
      import("../modules/payroll/views/payroll-associate-payments.vue"),
    props: (route) => ({
      associateId: route.query.associateId,
      payrollId: route.query.payrollId,
    }),
    beforeEnter: (to, from, next) => {
      const fromActivePayroll =
        from.name === "active-payroll" || from.name === "payroll";

      if (!to.query.associateId || !to.query.payrollId) {
        next({ name: "active-payroll" });
      } else if (!fromActivePayroll) {
        next();
      } else {
        next();
      }
    },
    meta: {
      title: "Payroll Associate Payment Requests - ePeople Time",
      permissions: ["validate_payments", "process_payments"],
    },
  },
  {
    path: "/project-shift-auths",
    name: "project-shift-auths",
    component: ProjectShiftAuths,
    props: (route) => {
      return {
        filter: {
          end: route.query.end,
          start: route.query.start,
          role: route.query.role,
          associate_ids: normalizeToArray(route.query.associate_ids),
          auth_types: normalizeToArray(route.query.auth_types),
          reviewer_ids: normalizeToArray(route.query.reviewer_ids),
          provider_ids: normalizeToArray(route.query.provider_ids),
          payment_type: route.query.payment_type,
          assigned_to_me: route.query.assigned_to_me,
          status: route.query.status,
        },
      };
    },
    meta: {
      title: "Shift Authorizations - ePeople Time",
      permissions: ["manage_authorizations"],
    },
  },
  {
    path: "/associate-profiles",
    name: "associate-profiles",
    component: AssociateProfiles,
    props: (route) => ({
      filters: {
        role: normalizeToArray(route.query.role),
        associates: normalizeToArray(route.query.associates),
        unverified: route.query.unverified,
        department: normalizeToArray(route.query.department),
        agency: normalizeToArray(route.query.agency),
        payment_type: normalizeToArray(route.query.payment_type),
        status: route.query.status,
      },
    }),
    meta: {
      title: "Associate Profiles - ePeople Time",
      permissions: ["manage_associates"],
    },
  },
  {
    path: "/create-user-with-associate",
    name: "create-user-with-associate",
    component: CreateUserWithAssociate,
    props: true,
    meta: {
      title: "Create User With Associate - ePeople Time",
      permissions: ["manage_associates"],
    },
  },
  {
    path: "/daily-summary",
    name: "daily-summary",
    component: GeneralDailyReport,
    props: (route) => ({
      reportDate: route.query.reportDate,
    }),
    meta: {
      title: "Daily Summary - ePeople Time",
      permissions: ["manage_shifts"],
    },
  },
  {
    path: "/activity",
    name: "activity",
    component: AssociateOverview,
    props: true,
    meta: {
      title: "Associate Activity - ePeople Time",
      permissions: ["manage_shifts"],
    },
  },
  {
    path: "/time-off",
    name: "time-off",
    component: TimeOffRequests,
    props: true,
    meta: {
      title: "Time Off Requests - ePeople Time",
      permissions: ["manage_shifts"],
    },
  },
  {
    path: "/shift-card-report",
    name: "shift-card-report",
    component: () => import("../modules/report/views/report-shift-cards.vue"),
    props: true,
    meta: { title: "Shift Card Report - ePeople Time" },
  },
  {
    path: "/ppd-audit-report",
    name: "ppd-audit-report",
    component: () => import("../modules/report/views/report-ppd-audit.vue"),
    props: true,
    meta: { title: "PPD Audit Export Report - ePeople Time" },
  },
  {
    path: "/alert-store",
    name: "alert-store",
    component: AlertStore,
    props: true,
    meta: {
      title: "Alert Store - ePeople Time",
      permissions: ["resolve_alerts"],
    },
  },

  {
    path: "/dev",
    name: "dev",
    component: Dev,
    props: true,
    meta: { title: "Dev - ePeople Time" },
  },
  {
    path: "/documents-store",
    name: "documents-store",
    component: () => import("../modules/document/views/document-store.vue"),
    props: (route) => {
      return {
        filters: {
          type: route.query.type,
          group: route.query.group,
          initial_document: route.query.initial_document,
          with_agreement: route.query.with_agreement,
          name: route.query.name,
        },
      };
    },
    meta: {
      title: "Documents Store - ePeople Time",
      permissions: ["manage_documents"],
    },
  },
  {
    path: "/onboarding",
    name: "onboarding",
    component: () => import("../modules/sequence/views/sequence.vue"),
    props: (route) => {
      return {
        filters: {
          payment_type: route.query.payment_type,
          legal_entity: route.query.payment_type,
        },
        type: "onboarding",
      };
    },
    meta: {
      title: "Onboarding - ePeople Time",
      permissions: ["manage_documents"],
    },
  },
  {
    path: "/trainings",
    name: "trainings",
    component: () => import("../modules/sequence/views/sequence.vue"),
    props: (route) => {
      return {
        filters: {
          payment_type: route.query.payment_type,
          legal_entity: route.query.payment_type,
        },
        type: "training",
      };
    },
    meta: {
      title: "Trainings - ePeople Time",
      permissions: ["manage_documents"],
    },
  },
  {
    path: "/departure",
    name: "departure",
    component: () => import("../modules/sequence/views/sequence.vue"),
    props: (route) => {
      return {
        filters: {
          payment_type: route.query.payment_type,
          legal_entity: route.query.payment_type,
        },
        type: "departure",
      };
    },
    meta: {
      title: "Departure - ePeople Time",
      permissions: ["manage_documents"],
    },
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const hasToken = store.state.applicationState.token;

  // Check if the route is public
  const isPublic = to.meta && to.meta.public;

  if (!hasToken && !isPublic) {
    sessionStorage.setItem("redirectPath", to.fullPath);
    return next("/auth");
  }

  document.title = to.meta?.title || "ePeople Time";

  // Redirect from /auth to home if the user already has a token
  if (to.path === "/auth" && hasToken) {
    const redirectPath = sessionStorage.getItem("redirectPath") || "/";
    sessionStorage.removeItem("redirectPath");
    return next(redirectPath);
  }

  next();
});

export default router;
